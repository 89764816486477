import React, { useState } from 'react';
import emailjs from '@emailjs/browser';
import { Form } from 'react-bootstrap';

const ContactForm = () => {
  const [submitStatus, setSubmitStatus] = useState('idle');

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [message, setMessage] = useState('');

  const [nameError, setNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [mobileError, setMobileError] = useState('');
  const [messageError, setMessageError] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitStatus('loading');

    let err = false;
    // console.log(form.current);

    const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    const mobileRegex = /^9[78]\d{8}$/;
    if (!name) {
      setNameError(' name  is compulsary !');
      err = true;
    }
    if (!email) {
      setEmailError(' email is compulsary !');
      err = true;
    } else if (!emailRegex.test(email)) {
      setEmailError('Please enter a valid email');
      err = true;
    }
    if (!message) {
      setMessageError(' message is compulsary !');
      err = true;
    }
    if (!mobile) {
      setMobileError(' mobile number is compulsary !');
      err = true;
    } else if (!mobileRegex.test(mobile)) {
      // setMobileError('Please provide a valid mobile number');
      // err = true;
    }
    if (!err) {
      const data = { name, email, mobile, message };
      emailjs
        .send(
          process.env.REACT_APP_EMAILJS_SERVICE_ID,
          process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
          data,
          process.env.REACT_APP_EMAILJS_PUBLIC_KEY
        )
        .then(
          (result) => {
            setSubmitStatus('success');

            clearForm();
            setTimeout(() => {
              setSubmitStatus('idle');
            }, 3000);
          },
          (error) => {
            console.log(error.text);
            setSubmitStatus('idle');
          }
        );
    } else {
      setSubmitStatus('idle');
    }
  };

  const clearForm = () => {
    setName('');
    setNameError('');
    setEmail('');
    setEmailError('');
    setMessage('');
    setMessageError('');
    setMobile('');
    setMobileError('');
  };

  return (
    <div className='contact-form'>
      <Form onSubmit={handleSubmit} className='contact-form-main'>
        <Form.Group className='mb-3' controlId='formBasicEmail'>
          <Form.Label>Name</Form.Label>
          <Form.Control
            id='name'
            type='text'
            value={name}
            placeholder='Enter your name'
            onChange={(e) => {
              setName(e.target.value);
              setNameError('');
            }}
          />

          {nameError && <span className='errorMessage'>{nameError}</span>}
        </Form.Group>
        <Form.Group className='mb-3' controlId='formBasicEmail'>
          <Form.Label>Phone Number</Form.Label>
          <Form.Control
            id='mobile'
            type='text'
            value={mobile}
            placeholder='Enter your phone number'
            onChange={(e) => {
              setMobile(e.target.value);
              setMobileError('');
            }}
          />
          {mobileError && <span className='errorMessage'>{mobileError}</span>}{' '}
        </Form.Group>
        <Form.Group className='mb-3' controlId='formBasicEmail'>
          <Form.Label>Email</Form.Label>
          <Form.Control
            id='email'
            type='text'
            value={email}
            placeholder='Enter your email'
            onChange={(e) => {
              setEmail(e.target.value);
              setEmailError('');
            }}
          />
          {emailError && <span className='errorMessage'>{emailError}</span>}{' '}
        </Form.Group>

        <Form.Group className='mb-3' controlId='exampleForm.ControlTextarea1'>
          <Form.Label>Message</Form.Label>
          <Form.Control
            as='textarea'
            id='message'
            type='text'
            rows={5}
            value={message}
            placeholder='Enter your message'
            onChange={(e) => {
              setMessage(e.target.value);
              setMessageError('');
            }}
          />
          {messageError && <span className='errorMessage'>{messageError}</span>}
        </Form.Group>

        <button type='submit' className='bton bton--primary bton--md'>
          {submitStatus === 'loading'
            ? 'Sending...'
            : submitStatus === 'success'
            ? 'Success'
            : 'Submit'}
        </button>
      </Form>
    </div>
  );
};

export default ContactForm;
