import React, { Fragment } from 'react';
import { Container } from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel';
import { CAROUSEL_QUERY } from '../../queries/carousals';
import Query from '../Query';
function Index() {
  return (
    <Fragment>
      <Container>
        <Query query={CAROUSEL_QUERY} id={'cG9zdDoyMg=='}>
          {({ data: { page } }) => {
            return (
              <>
                <Carousel fade interval={4000}>
                  {page?.carousel.carousel.map((a) => {
                    return (
                      <Carousel.Item>
                        <figure className='carouselWrapper'>
                          <img
                            className='carouselWrapper-image'
                            src={a.sourceUrl}
                          />
                        </figure>
                      </Carousel.Item>
                    );
                  })}
                </Carousel>
              </>
            );
          }}
        </Query>
      </Container>
    </Fragment>
  );
}

export default Index;
