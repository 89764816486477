import React from 'react';
import { Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
// import BlogImage from '../../assets/images/blogimage.jpg';

const BlogCard = ({ blog }) => {
  return (
    <>
      <Col md={4}>
        {/* <section className='blogCardWrapper'>
          <figure className='blogCardWrapper-image'>
            <img src={blog?.blog?.heroImage?.sourceUrl} alt='' />
          </figure>
          <section className='blogCardWrapper-title'>
            <h1>{blog?.title}</h1>
            <Link
              to={`/blogs/${blog?.slug}`}
              className='blogCardWrapper-title-btn'
            >
              READ MORE
            </Link>
          </section>
          <section className='blogCardWrapper-cat'>
            {blog?.blog?.blogCategory?.name}
          </section>
          <section className='blogCardWrapper-overlay'></section>
        </section> */}
        <Link
          className='news-card'
          to={`/news/${blog?.slug}`}
          rel='noopener noreferrer'
        >
          <img src={blog?.blog?.heroImage?.sourceUrl} alt='image' />
          <div className='news-card__content'>
            <h3 className='news-card__title'>{blog?.title}</h3>
            <p className='news-card__description'>
              Category: {blog?.blog?.blogCategory?.name}
            </p>
          </div>
        </Link>
      </Col>
    </>
  );
};

export default BlogCard;
