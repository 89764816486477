import React, { useEffect, useState } from 'react';
import { useQuery, gql } from '@apollo/client';
import { Container, Form, Row } from 'react-bootstrap';
import CloseButton from 'react-bootstrap/CloseButton';
import BlogCard from '../../components/BlogCard';
import BlogsLoader from '../../components/BlogsLoader';

const Blogs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const GET_BLOGS = gql`
    query fetchBlogs {
      blogs {
        nodes {
          title
          id
          slug

          blog {
            heroImage {
              sourceUrl
            }
            blog
            blogCategory {
              name
              id
              slug
            }
          }
        }
      }
    }
  `;
  const GET_CATEGORIES = gql`
    query fetchCategories {
      categories(where: { exclude: "dGVybTox" }) {
        nodes {
          name
          slug
        }
      }
    }
  `;

  const { loading: blogsLoading, data: blogsData } = useQuery(GET_BLOGS);
  const { data: categories } = useQuery(GET_CATEGORIES);
  const [blogsLength, setBlogLength] = useState(1);
  const [blogs, setBlogs] = useState([]);
  const [categoriesList, setCategoriesList] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [category, setCategory] = useState(null);
  useEffect(() => {
    if (categories) {
      categories &&
        setCategoriesList(
          categories.categories.nodes.map((i) => {
            return { name: i.name, slug: i.slug };
          })
        );
    }
  }, [categories]);

  useEffect(() => {
    if (selectedCategories.length > 0) {
      setBlogs(
        blogsData?.blogs?.nodes.filter((i) => {
          return selectedCategories
            .map((j) => j.slug)
            .includes(i.blog.blogCategory.slug);
        })
      );
    } else {
      setBlogs(blogsData?.blogs?.nodes);
    }
  }, [selectedCategories, blogsData]);
  return (
    <div>
      <Container>
        <section className='blogHolder'>
          <section className='blogHolder-top'>
            <h1 className='blogHolder-top-title'>News Section</h1>
            {categoriesList && categoriesList.length > 0 && (
              <Form.Select
                aria-label='Default select example'
                value={category?.name || 'Filter Category'}
                onChange={(e) => {
                  const exists = selectedCategories.find(
                    (i) => i.slug === e.target.value
                  );
                  if (!exists) {
                    const cat = categoriesList.find(
                      (i) => i.slug === e.target.value
                    );
                    if (cat) {
                      setSelectedCategories([cat, ...selectedCategories]);
                    }
                  }
                  // if (e.target.value.value !== '0') {
                  //   setCategory(e.target.value);
                  //   setSelectedCategories([category, ...selectedCategories]);
                  // }
                }}
              >
                <option value={{ slug: null, name: null }}>
                  Filter Categories
                </option>
                {categoriesList.map((i) => (
                  <option key={i.slug} value={i.slug}>
                    {i.name}
                  </option>
                ))}
              </Form.Select>
            )}
          </section>
        </section>
        <section className='blogHolder-pills'>
          {selectedCategories.length > 0 &&
            selectedCategories.map((i) => (
              <span>
                {i.name}{' '}
                <CloseButton
                  onClick={() =>
                    setSelectedCategories(
                      selectedCategories.filter((j) => j.slug !== i.slug)
                    )
                  }
                />{' '}
              </span>
            ))}
        </section>

        <Row>
          {!blogsLoading ? (
            blogs
              ?.map((blog) => <BlogCard blog={blog} key={blog.id} />)
              .filter((i, index) => blogsLength && i)
          ) : (
            <BlogsLoader></BlogsLoader>
          )}

          {blogsLength > 15 && (
            <section className='text-center'>
              <button
                className='bton bton--sm bton--secondary'
                onClick={() => setBlogLength(blogsLength + 1)}
              >
                Load more
              </button>
            </section>
          )}
        </Row>
      </Container>
    </div>
  );
};

export default Blogs;
